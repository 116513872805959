<template>
    <div class="dashboard-users-view">
        <div class="dashboard-users-view__filters">
            <BaseInput
                :debounce="400"
                :placeholder="$t('searchPlaceholder')"
                block
                trim
                @input="nameSearchQuery = $event"
            >
                <template #icon>
                    <SearchIcon width="16" height="16" />
                </template>
            </BaseInput>

            <BaseButton size="small" @click="openCreateModal">
                {{ $t('create') }}
            </BaseButton>
        </div>

        <div class="dashboard-users-view__content">
            <UsersTable ref="usersTable" :filter-name="nameSearchQuery" />
        </div>
    </div>
</template>

<script>
import BaseButton from '../redesigned/BaseButton'
import BaseInput from '../redesigned/BaseInput'
import CreateUserModal from '../redesigned/CreateUserModal'
import SearchIcon from '../icons/SearchIcon'
import UsersTable from '../UsersTable'

export default {
    name: 'DashboardUsersView',
    components: {
        BaseButton,
        BaseInput,
        SearchIcon,
        UsersTable,
    },
    data() {
        return {
            nameSearchQuery: '',
        }
    },
    methods: {
        openCreateModal() {
            this.$modal.show(
                CreateUserModal,
                { onSave: this.$refs.usersTable.fetchUsers },
                { width: 400, height: '75%' }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "create": "Create user",
        "searchPlaceholder": "Name"
    },
    "de": {
        "create": "Neuer Benutzer",
        "searchPlaceholder": "Name"
    },
    "fr": {
        "create": "Nouvel utilisateur",
        "searchPlaceholder": "Nom"
    },
    "it": {
        "create": "Nuovo utente",
        "searchPlaceholder": "Nome"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-users-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input,
        .multiselect {
            max-width: 200px;
        }

        .base-button {
            padding: 10px 16px;
            background-color: #fff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            line-height: 20px;
            font-weight: 600;
            font-size: 15px;
            color: $color-primary-text;
        }
    }

    &__content {
        overflow-y: auto;
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                margin-right: 0;
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
