<template>
    <VMultiselect
        v-bind="$attrs"
        class="base-multiselect"
        :class="{
            'base-multiselect--block': block,
        }"
        :close-on-select="!multiple"
        :hide-selected="multiple"
        :max="$attrs.max || (multiple ? options.length : null)"
        :multiple="multiple"
        :options="options"
        :placeholder="$attrs.placeholder || $t('defaultPlaceholder')"
        :show-labels="false"
        :tag-placeholder="null"
        v-on="$listeners"
    >
        <template #maxElements>
            {{ $t('shared.noOptions') }}
        </template>

        <template #noOptions>
            {{ $t('shared.noOptions') }}
        </template>

        <template #noResult>
            {{ $t('shared.noSearchResult') }}
        </template>

        <template #option="{option}">
            <slot name="option" :option="option" />
        </template>

        <slot />
    </VMultiselect>
</template>

<script>
import VMultiselect from 'vue-multiselect'

export default {
    name: 'BaseMultiselect',
    components: {
        VMultiselect,
    },
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
    },
}
</script>

<i18n>
{
    "en": {
        "defaultPlaceholder": "Select option"
    },
    "de": {
        "defaultPlaceholder": "Bitte auswählen"
    },
    "fr": {
        "defaultPlaceholder": "Veuillez sélectionner"
    },
    "it": {
        "defaultPlaceholder": "Selezionare"
    }
}
</i18n>

<style lang="scss">
.base-multiselect[group-label] {
    // additional indent to elements in groups
    .multiselect__option {
        padding: 12px 32px;

        &--group {
            padding: 12px 20px;
        }
    }
}

.base-multiselect {
    color: $color-text-new;

    &.multiselect--active {
        .multiselect__select {
            top: 0;
        }

        .multiselect__tags {
            box-shadow: none;
            border-radius: 4px;
        }
    }

    &.multiselect--disabled {
        background-color: transparent;
    }

    .multiselect__select {
        width: 40px;
        height: 40px;

        &::before {
            border-color: $color-gray-dark-new transparent transparent;
            color: $color-gray-dark-new;
        }
    }

    .multiselect__tags {
        padding: 10px 12px 0;
        padding-right: 52px;
        background-color: $color-gray-lighter-new;
        border: none;
        line-height: 20px;
    }

    .multiselect__tag {
        padding: 3px 20px 3px 8px;
        background-color: #fff;
        border-radius: 4px;
        color: $color-text-new;
    }

    .multiselect__tag-icon {
        margin: 0;
        line-height: 18px;

        &::after {
            color: $color-gray-dark-new;
            font-size: 16px;
            font-weight: 300;
        }

        &:focus,
        &:hover {
            background-color: transparent;

            &::after {
                color: $color-text-new;
            }
        }
    }

    .multiselect__strong {
        margin: 0 0 5px;
        padding: 0 6px 0 4px;
        background-color: #fff;
        border-radius: 4px;
        font-weight: 400;
        color: $color-text-new;
    }

    .multiselect__placeholder {
        padding: 0;
        color: rgba(0, 0, 0, 0.4);
    }

    .multiselect__input,
    .multiselect__single {
        margin: 0 0 10px;
        padding: 0;
        border-radius: 0;
        color: $color-text-new;

        &::placeholder {
            color: rgba(0, 0, 0, 0.4);
        }
    }

    .multiselect__content-wrapper {
        margin: 6px 0 12px;
        padding: 8px 0;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
        border-radius: 8px;
    }

    .multiselect__option {
        padding: 12px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .multiselect__option--group {
        background-color: #fff;
        color: $color-gray-darker !important;
        font-size: $fs-small;

        &.multiselect__option--disabled {
            background-color: #fff !important;
        }
    }

    .multiselect__option--highlight,
    .multiselect__option--selected {
        background-color: $color-gray-lighter-new;
        color: $color-text-new;

        &.multiselect__option--group {
            color: $color-gray-darker;
        }
    }

    &--block {
        &.multiselect--active {
            .multiselect__tags {
                border-radius: 8px;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
            }
        }

        .multiselect__tags {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        }

        .multiselect__tag {
            background-color: $color-gray-light-new;
        }

        .multiselect__strong {
            background-color: $color-gray-light-new;
        }
    }
}
</style>
